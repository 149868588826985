<template>
  <div>
    <frame>
      <!-- 头部 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">微信参数配置</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>

      <!-- 数据列表文字一行 -->
      <div class="screen">
        <div class="screen_left">
          <i class="el-icon-search"></i>
          数据列表
        </div>
        <div class="info">
          <span v-show="info == 1">参数信息</span>
          <span v-show="info == 2">编辑信息</span>
          <span v-show="info == 3">修改信息</span>
          <span v-show="info == 4">添加信息</span>
          </div>
      </div>

      <!-- 添加表单主题部分 -->
      <div class="center">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
          :rules="ruleValidate"
        >
          <el-form-item label="选择商户：" v-show="merchantShow">
            <el-select
              style="width: 300px"
              v-model="business_id"
              placeholder="请选择商户"
              @change="changeMerchant(business_id)"
            >
              <el-option
                v-for="item in businessList"
                :key="item.business_name"
                :label="item.business_name"
                :value="item.business_id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="AppID：" prop="appId">
            <el-input
              class="rt-input"
              :disabled="read"
              v-model="ruleForm.mini_app_id"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="secret：" prop="secret">
            <el-input
              :disabled="read"
              v-model="ruleForm.mini_app_secret"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="商户号：" prop="start_price">
            <el-input
              :disabled="read"
              v-model="ruleForm.mini_merchant"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="支付密钥：" prop="increase_quantity">
            <el-input
              :disabled="read"
              v-model="ruleForm.paykey"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="设备号：" prop="termNo">
            <el-input
              :disabled="read"
              v-model.number="ruleForm.termNo"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              @click="add()"
              type="primary"
              style="width: 100px"
              v-if="addShow"
              >添加</el-button
            >
            <el-button
              @click="cancelAdd()"
              type="primary"
              style="width: 100px"
              v-show="cancelShow"
              >取消添加</el-button
            >
            <el-button
              @click="edit()"
              v-if="editShow"
              type="primary"
              style="width: 100px"
              >编辑</el-button
            >
            <el-button
              @click="submitForm('ruleForm')"
              type="primary"
              style="width: 100px"
              v-show="subShow"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";
export default {
  data() {
    return {
      info:1,
      cancelShow:false,
      merchantShow: false, //不同权限下的显示隐藏
      storeShow: false,
      read: true, //是否为只读
      businessList: [{}], //商户列表
      business_id: "", //选中的商户ID
      editShow: false, //编辑按钮的显示隐藏
      addShow: true, //添加按钮的显示隐藏
      subShow: false, //提交按钮的显示隐藏
      system_id: "",
      sel: 0, //编辑还是者添加的状态
      //提交的表单数据
      ruleForm: {
		  mini_app_id:"",
		  mini_app_secret:"",
		  mini_merchant:"",
		  paykey:"",
		  termNo:"",
		  
  
	  },
      //表单验证数据
      ruleValidate: {
        platform_name: [
          {
            required: true,
            message: "请输入平台名称", //账号
            trigger: "blur",
          },
        ],
        platform_title: [
          {
            required: true,
            message: "请输入店铺平台标题", //店铺
            trigger: "blur",
          },
        ],
        platform_desc: [
          {
            required: true,
            message: "请输入平台描述", //地址
            trigger: "blur",
          },
        ],
        key_word: [
          {
            required: true,
            message: "请输入关键词", //手机号
            trigger: "blur",
          },
        ],
        hot_line: [
          { required: true, message: "客服热线不能为空" },
          { type: "number", message: "客服热线必须为数字值" },
        ],
      },
    };
  },
  methods: {
    //选中商户事件
    changeMerchant(business_id) {
	console.log(this.sel)
      console.log(business_id);
      //添加
      if (this.sel == 2) {
        this.$request
          .systemInfo({
            token: this.$storage.getLocal("token"), //登陆进来的tooken
            admin_type: this.$storage.getLocal("type"), //身份
            business_id: business_id,
          })
          .then((res) => {
            console.log(res);
			//请求接口
            if (res.code == 0) {
              console.log(222);
              // this.$router.push({ path: "freight" });
              if (res.data) {
                //消息提示 有数据的情况赋值
				this.ruleForm = res.data;
                const h = this.$createElement;
                this.$notify({
                  title: "消息提示",
                  message: h(
                    "i",
                    { style: "color: red;font-size:28px" },
                    "此商户已有数据，请选择其他商户添加"
                  ),
                });
              } else {
				 //没数据解除输入框禁用，添加秘钥
                console.log("niu")
                this.read = false;
                // this.ruleForm = res.data;
                this.editShow = true;
                this.business_id = res.data.business_id;
				console.log(11111111111111,this.read);
              }
            } else {
              alert("请求失败");
            }
          });

        // 编辑
      } else {
        this.$request
          .systemInfo({
            token: this.$storage.getLocal("token"), //登陆进来的tooken
            admin_type: this.$storage.getLocal("type"), //身份
            business_id: business_id,
          })
          .then((res) => {
            console.log(res);
            if (res.code == 0) {
              // this.$router.push({ path: "freight" });
              this.addShow = true
              if (res.data == null) {
                //消息提示
                const h = this.$createElement;
                this.$notify({
                  title: "消息提示",
                  message: h(
                    "i",
                    { style: "color: red;font-size:28px" },
                    "此商户暂无数据，请添加"
                  ),
                });
                this.ruleForm = [];
                this.editShow = false;
              } else {
                this.read = true;
                this.ruleForm = res.data;
                this.editShow = true;
                // this.business_id = res.data.business_id
                console.log(typeof res.data.business_id);
                this.business_id = res.data.business_id;
              }
            } else {
              alert("请求失败");
            }
          });
      }
    },
    //编辑按钮
    edit() {
      this.info = 2
      this.read = false;
      this.system_id = this.ruleForm.system_id;
      this.subShow = true;
      this.addShow = false;
    },
    //添加按钮
    add() {
      this.cancelShow = true;
	  // this.read = false;
      this.addShow = false;
      this.subShow = true;
      //  (this.read = false),
      this.ruleForm = {};
      this.business_id = "";
      this.sel = 2;
      this.system_id = "";
      this.editShow = false;
      this.info = 4
    },
    //取消添加按钮
    cancelAdd(){
      location.reload();
    },

    //表单提交按钮
    submitForm(formName) {
      let admin_type = this.$storage.getLocal("type");
      if (admin_type == 1) {
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request
            .setSystem({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              business_id: this.business_id, //	是	int	门店id 超管传
              system_id: this.system_id, //		非	string	传id走编辑
              mini_app_id: this.ruleForm.mini_app_id, //	是	string	小程序APPID
              mini_app_secret: this.ruleForm.mini_app_secret, //	是	string	小程序APPsecret
              mini_merchant: this.ruleForm.mini_merchant, //		是	string	小程序商户号
              paykey: this.ruleForm.paykey, //		是	string	支付密钥
              termNo: this.ruleForm.termNo, //	是	int	设备号
            })
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                // this.$router.push({ path: "freight" });
                this.read = true;
                this.info = 1

                //消息提示
                const h = this.$createElement;
                this.$notify({
                  title: "消息提示",
                  message: h(
                    "i",
                    { style: "color: red;font-size:28px" },
                    "提交成功"
                  ),
                });
              } else {
                alert("请求失败");
              }
            });
        } else {
          console.log("提交失败");
          return false;
        }
      });
    },
    
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //刷新页面按钮
    refresh() {
      location.reload();
    },
  },

  created() {
    let admin_type = this.$storage.getLocal("type");
    if (admin_type == 1) {
      // this.read = false;
      //请求商户列表接口
      this.$request.businessList({}).then((res) => {
        console.log(res);
        this.businessList = res.data;
        this.ruleForm.businessList = res.data;
      });
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (admin_type == 2) {
      this.storeShow = true;
      this.editShow = true;
      this.addShow = false;
      this.$request
        .systemInfo({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            // this.$router.push({ path: "freight" });
            this.read = true;
            this.ruleForm = res.data;
            // this.business_id = res.data.business_id
            console.log(typeof res.data.business_id);
            this.business_id = res.data.business_id;
          } else {
            alert("请求失败");
          }
        });
    }
  },
  components: {
    frame,
  },
};
</script>

<style  scoped>
.info{
  float: left;
  width: 100px;
  height: 45px;
  line-height: 45px;
  margin-left: 400px;
}
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 40px;
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.center {
  width: 40%;
  margin-left: 32%;
  margin-top: 50px;
  /* height: 500px; */
  /* border: 1px solid red; */
}
/* 上传图片的样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-input.is-disabled /deep/ .el-input__inner {
  color: black;
  font-weight: 600;
}
</style>